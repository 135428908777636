<template>
  <v-app-bar flat dense color="white">
    <img src="@/assets/icons/logo.svg" alt="inspire" />
    <!-- <div class="ml-4">
      <location-select-box
        item-text="name"
        item-value="id"
        placeholder="Location"
        dense
        hide-details
        class="select-box-width"
        v-model="locationId"
      ></location-select-box>
    </div> -->
    <v-spacer></v-spacer>
    <div v-for="(item, key) in items" :key="key">
      <v-menu
        v-if="item.children.length > 0"
        v-model="item.active"
        absolute
        offset-y
        style="max-width: 600px"
      >
        <template v-slot:activator="{ on }">
          <!-- <div
            v-on="on"
            class="mx-2 font-weight-bold text-color-main caption app-toolbar-link px-4 cursor-pointer"
            :class="{ 'active-link': item.active }"
          > -->
          <div
            v-on="on"
            class="mx-2 font-weight-bold text-color-main caption app-toolbar-link px-4 cursor-pointer"
          >
            {{ item.name }}
          </div>
        </template>

        <v-list dense>
          <v-list-item
            v-for="(child, index) in item.children"
            :key="index"
            @click="MIXINS_GO_TO_PAGE(child.link)"
          >
            <v-list-item-title>{{ child.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <span
        v-else
        @click="MIXINS_GO_TO_PAGE(item.link)"
        class="mx-2 font-weight-bold text-color-main caption app-toolbar-link px-4 cursor-pointer"
      >
        <!-- <span
        v-else
        @click="MIXINS_GO_TO_PAGE(item.link)"
        class="mx-2 font-weight-bold text-color-main caption app-toolbar-link px-4 cursor-pointer"
        :class="{ 'active-link': item.active }"
      > -->
        {{ item.name }}
      </span>
    </div>
    <div v-if="isAdmin">
      <div v-for="(item, key) in adminItems" :key="key">
        <v-menu
          v-if="item.children.length > 0"
          v-model="item.active"
          absolute
          offset-y
          style="max-width: 600px"
        >
          <template v-slot:activator="{ on }">
            <div
              v-on="on"
              class="mx-2 font-weight-bold text-color-main caption app-toolbar-link px-4 cursor-pointer"
            >
              {{ item.name }}
            </div>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(child, index) in item.children"
              :key="index"
              @click="MIXINS_GO_TO_PAGE(child.link)"
            >
              <v-list-item-title>{{ child.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <v-btn icon>
      <v-icon>mdi-account-outline</v-icon>
    </v-btn>
    <!-- <v-btn icon @click="logout()">
      <v-icon>mdi-cog-outline</v-icon>
    </v-btn> -->
    <v-btn icon @click="logout()">
      <v-icon>mdi-logout</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import { RepositoryFactory } from "@/services/repositories/repositoryFactory";
import Storage from "@/services/storage.js";

const signUpRepository = RepositoryFactory.get("sign-up");
let storage = new Storage();

export default {
  data: () => ({
    items: [
      {
        name: "Dashboard",
        routeName: "Dashboard",
        link: "/dashboard",
        active: false,
        children: []
      },
      {
        name: "App Management",
        routeName: "AppManagement",
        link: "",
        active: false,
        children: [
          {
            name: "Devotionals",
            routeName: "Devotionals",
            link: "/devotionals",
            active: false
          },
          {
            name: "Featured Videos",
            routeName: "FeaturedVideos",
            link: "/featured-videos",
            active: false
          },
          // {
          //   name: "Worships",
          //   routeName: "Worships",
          //   link: "/worships",
          //   active: false
          // },
          // {
          //   name: "Experiences",
          //   routeName: "Experiences",
          //   link: "/experiences",
          //   active: false
          // },
          // {
          //   name: "Events",
          //   routeName: "Events",
          //   link: "/events",
          //   active: false
          // },
          // {
          //   name: "People",
          //   routeName: "People",
          //   link: "/app-managements",
          //   active: false
          // },
          // {
          //   name: "Badges",
          //   routeName: "Badges",
          //   link: "/badges",
          //   active: false
          // },
          {
            name: "Users",
            routeName: "Users",
            link: "/users",
            active: false
          },
          {
            name: "Prayers",
            routeName: "Prayers",
            link: "/prayers",
            active: false
          },
          // {
          //   name: "Giving Videos",
          //   routeName: "Giving Videos",
          //   link: "/giving-videos",
          //   active: false
          // }
          // {
          //   name: "Classes",
          //   routeName: "Classes",
          //   link: "/classes",
          //   active: false
          // }
        ]
      },
      {
        name: "Messaging",
        routeName: "Messaging",
        link: "",
        active: false,
        children: [
          {
            name: "Chat",
            routeName: "ChatSupports",
            link: "/chat-supports",
            active: false
          },
          {
            name: "Announcements",
            routeName: "Announcements",
            link: "/announcements",
            active: false
          }
        ]
      }
    ],
    adminItems: [
      {
        name: "Reports and Analytics",
        routeName: "ReportsAndAnalytics",
        link: "/reports-and-analytics",
        active: false,
        children: [
          {
            name: "Apple Pay Donations",
            routeName: "Donations",
            link: "/donations",
            active: false
          },
          {
            name: "Donation Logs",
            routeName: "DonationLogs",
            link: "/donation-logs",
            active: false
          },
          {
            name: "App Activities",
            routeName: "AppActivities",
            link: "/reports/app-activities",
            active: false
          }
        ]
      }
    ],
    locationId: null
  }),
  watch: {
    $route: {
      handler: function(value) {
        // console.log({ value });
        this.items.map(item => {
          if (item.routeName === value.name) {
            item.active = true;
          } else {
            item.active = false;
          }

          return item;
        });
      },
      immediate: true
    },
    locationId(value) {
      storage.set("location_id", value);
      this.$store.commit("eventModule/SET_LOCATION_ID", value);
    }
  },
  methods: {
    logout() {
      signUpRepository
        .logout()
        .then(response => {
          console.log({ response });
          storage.clear();
          this.MIXINS_GO_TO_PAGE("/login");
        })
        .catch(error => {
          console.log({ error });
        });
    },
    async setLocation() {
      let user = await storage.get("user");

      this.locationId = user.location_id;

      storage.set("location_id", this.locationId);
    }
  },
  computed: {
    isAdmin() {
      let user = JSON.parse(localStorage.getItem("user"));
      if (user.user_type === "admin" || user.user_type === "superadmin") {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.setLocation();
  }
};
</script>

<style lang="scss" scoped>
.active-link {
  border-bottom: 2px solid #4ec8eb;
}
.app-toolbar-link {
  padding: 5px;

  &:hover {
    background: #f4f4f4;
    border-bottom: 2px solid #4ec8eb;
  }
}
</style>
